module.exports = [
    {
        social: [
            {
                service: 'facebook',
                href: 'https://www.facebook.com/Pielalinja-oy-374171629700358/',
            },
        ],
    },
    
]